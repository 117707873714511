// import logo from './logo.svg';
// import './App.css';
import React, { useEffect, useState } from "react"
import NavigationBar from "../components/NavigationBar";
import { useNavigate, useLocation } from 'react-router-dom';
import api from "../services/api"
import { Alert, Container, Row, Col, Form, Button, FloatingLabel } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Figure from 'react-bootstrap/Figure';
import girlReadingImage from '../assets/high-angle-girl-library-reading-mini.jpg';
import CookieConsent from "react-cookie-consent";

function Landing() {
  const [logged, setLogged] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("@radar_concursos:access_token") && localStorage.getItem("@radar_concursos:refresh_token")){
      navigate('/cargos');
    }
  }, []);

  return (
    <>
      <NavigationBar/>
      <main>
        <Container fluid className="d-flex align-items-center justify-content-center" style={{ height: '80vh' }}>
          <Row>
            <Col className="justify-content-end text-end pe-4 me-4">
              <Figure>
                <Figure.Image
                  alt="fotografia de uma mulher olhando para frente e sorrindo em frente à uma estante de livros enquanto segura um livro aberto"
                  style={{ maxHeight: '35vh' }}
                  src={girlReadingImage}
                />
                <Figure.Caption className="text-start">
                  <small className="text-muted">Cortesia de Freepik</small>
                </Figure.Caption>
              </Figure>
            </Col>
            <Col className="ms-4">
              <p className="fs-2 fw-bold">Acompanhe concursos</p>
              <p className="fs-5">Encontre, salve e seja notificado do concurso público certo para você</p>
              <p class="mt-4">
                <Button href="/entrar" className="me-1 btn-lg btn-secondary">Já tenho uma conta</Button>
                <Button href="/registrar" className="ms-1 btn-lg btn-primary">Registrar</Button>
              </p>
            </Col>
          </Row>
        </Container>
        <CookieConsent
          location="bottom"
          buttonText="Aceitar"
          expires={150}
        >
          ConcursoPubli.co usa cookies para melhorar sua experiência.
        </CookieConsent>
      </main>
      
    </>
  )}

export default Landing;
