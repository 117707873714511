// import logo from './logo.svg';
// import './App.css';
import React, { useCallback, useEffect, useRef, useState } from "react";

import api from "../services/api"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import NavigationBar from "../components/NavigationBar";
import Job from "../components/Job";
import JobDetail from "../components/JobDetail";
import Jobs from "../components/Jobs";
import FilterJobs from "../components/FilterJobs";
import { Routes, Route, useParams } from 'react-router-dom';

function Cargo() {
    const [logado, setLogado] = useState(false);
    const [cargoSelecionado, setCargoSelecionado] = useState(null);
    const [dadosEstruturados, setDadosEstruturados] = useState(null);
    const [error, setError] = useState(null);

    let {id, slug} = useParams();

    function updateCargoAtual(){
      api.get(`editais/cargos/${id}`).then(response => {
        setCargoSelecionado(response.data);
      }).catch(err => {
        console.log(err);
        setError('Erro ao carregar endereço');
      })
    }

    function getLocalizacao(){
      if (!cargoSelecionado){
        return "";
      }

      if (cargoSelecionado.edital.instituição.hasOwnProperty('municipio') && cargoSelecionado.edital.instituição.municipio){
        return cargoSelecionado.edital.instituição.municipio.nome;
      }

      return "";
    }

    function getRegiao(){
      if (!cargoSelecionado){
        return "";
      }

      if (cargoSelecionado.edital.instituição.hasOwnProperty('municipio') && cargoSelecionado.edital.instituição.municipio){
        return cargoSelecionado.edital.instituição.municipio.estado.nome;
      }

      if (cargoSelecionado.edital.instituição.hasOwnProperty('estado') && cargoSelecionado.edital.instituição.estado){
        return cargoSelecionado.edital.instituição.estado.nome;
      }

      return "";
    }

    useEffect(() => {
      updateCargoAtual();
    }, []);

    useEffect(() => {
      if (cargoSelecionado){
        setDadosEstruturados({
          "@context": "http://schema.org",
          "@type": "JobPosting",
          "description": cargoSelecionado.sobre,
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": getLocalizacao(),
              "addressRegion": getRegiao(),
              "addressCountry": "BR"
            }
          },
          "hiringOrganization": {
            "@type": "Organization",
            "name": cargoSelecionado.edital.instituição.nome,
          },
          "directApply": false,
          "title": cargoSelecionado.nome,
          "datePosted": cargoSelecionado.edital.data_inicio_inscricoes,
          "validThrough": cargoSelecionado.edital.data_fim_inscricoes,
          "baseSalary": {
            "@type": "MonetaryAmount",
            "currency": "BRL",
            "value": {
              "@type": "QuantitativeValue",
              "value": parseFloat(cargoSelecionado.salario_inicial),
              "unitText": "MONTH"
            }
          },
          "url": (process.env.REACT_APP_FRONTEND_URL ?? 'http://localhost:8000/') + "cargo/" + cargoSelecionado.id + "/" + cargoSelecionado.slug,
        });
      }
    }, [cargoSelecionado]);

    useEffect(() => {
      if (cargoSelecionado) {
        document.title = cargoSelecionado.nome + " - ConcursoPubli.co"
      }
    }, [cargoSelecionado]);

    return (
      <>
        <script type="application/ld+json">
          {JSON.stringify(dadosEstruturados)}
        </script>
        <NavigationBar logado={logado}/>
        <Container>
          <Row className="mt-5">
            <JobDetail jobData={cargoSelecionado} logado={logado} onSaved={() => updateCargoAtual(cargoSelecionado)}/>
          </Row>
        </Container>
      </>
    
  )}

export default Cargo;
