import React, { useEffect, useState } from "react"
// import ReactSearchBox from "react-search-box";
import { Navbar, Nav, Form, FormControl, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBriefcase, faUser, faBookmark } from '@fortawesome/free-solid-svg-icons';
import api from "../services/api"
import AuthService from "../services/AuthService"

import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { LinkContainer } from 'react-router-bootstrap'

export default function NavigationBar({ logado, children }) {
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    setLogged(AuthService.isAuthenticated());
  }, []);

  return (
    <Navbar bg="light" expand="lg" className="bg-body-tertiary">
      <Container>
      <LinkContainer to="/">
        <Navbar.Brand>
          <Nav.Link>
            <img
              src="/logo.svg"
              // width="30"
              // height="30"
              className="d-inline-block align-top"
              alt="ConcursoPubli.co"
            />
          </Nav.Link>
        </Navbar.Brand>
      </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <LinkContainer to="/cargos" className="nav-link icon-text text-center">
              <Nav.Link>
                <Row>
                  <Col xs={12}><FontAwesomeIcon icon={faBriefcase} /></Col>
                  <Col xs={12}><span>Vagas</span></Col>
                </Row>
              </Nav.Link>
            </LinkContainer>

          { logged &&
            <>
              {/* <LinkContainer to="#perfil" className="icon-text text-center">
                <Nav.Link>
                  <Row>
                    <Col xs={12}><FontAwesomeIcon icon={faUser} /></Col>
                    <Col xs={12}><span>Meu Perfil</span></Col>
                  </Row>
                </Nav.Link>
              </LinkContainer> */}

              <LinkContainer to="/sair" className="icon-text text-center">
                <Nav.Link>
                <Row>
                    <Col xs={12}><FontAwesomeIcon icon={faUser} /></Col>
                    <Col xs={12}><span>Sair</span></Col>
                  </Row>
                </Nav.Link>
              </LinkContainer>
            </>
          }
          { !logged &&
            <>
              <LinkContainer to="/registrar" className="nav-link icon-text text-center">
                <Nav.Link>
                  <Row>
                    <Col xs={12}><FontAwesomeIcon icon={faUser} /></Col>
                    <Col xs={12}><span>Registrar</span></Col>
                  </Row>
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to="/entrar" className="nav-link icon-text text-center">
                <Nav.Link>
                  <Row>
                    <Col xs={12}><FontAwesomeIcon icon={faUser} /></Col>
                    <Col xs={12}><span>Entrar</span></Col>
                  </Row>
                </Nav.Link>
              </LinkContainer>
            </>
          }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};