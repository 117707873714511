import Axios from "axios";
import React, { useEffect, useState } from "react"
import TimeAgo from 'react-time-ago';
import JavascriptTimeAgo from 'javascript-time-ago';
import pt from 'javascript-time-ago/locale/pt';
import { Card, Button, Collapse, Badge } from 'react-bootstrap';

JavascriptTimeAgo.addLocale(pt);
// import { useAuth } from "../../hooks/auth";
// import api from "../../services/api";

export default function Job({ jobData, isSelected, onClick }) {
    const handleClick = (event) => {
        event.preventDefault();
        if (onClick){
            onClick();
        }
    };

    const handleClose = (event) => {
        event.preventDefault();
        event.stopPropagation();
        console.log("Closing this")
    };

    function tipoLocalidade(){
        if (!(jobData.edital.instituição.municipio || jobData.edital.instituição.estado)){
            return "Federal";
        }

        return jobData.edital.instituição.municipio ? "Municipal" : "Estadual";
    }


    return (
        <div className="card my-1" style={{ backgroundColor: isSelected ? 'rgb(206, 245, 219)' : 'white', borderColor: isSelected ? '#0080003d' : 'rgba(147, 147, 147, 0.24)' }}>
            <Button style={{textAlign: 'left'}} variant="link" className="text-reset text-decoration-none p-0 my-1" onClick={handleClick}>
                <div className="card-body px-1">
                    <div className="d-flex justify-content-between">
                        <p className="card-title fs-6 fw-semibold">{jobData.nome}</p>
                        <button type="button" className="btn-close text-end" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <h6 className="card-subtitle mb-2 text-body-secondary small">
                        <Badge bg="primary" className="me-1">{tipoLocalidade()}</Badge>
                        <span className=""> </span>
                        <span>{jobData.edital.instituição.nome}</span>
                        <span className="mx-2">·</span>
                        <span><TimeAgo date={Date.parse(jobData.criado_em)} locale='pt'/></span>
                    </h6>
                    <Collapse in={isSelected}>
                        <p className="mt-3 mb-0"><span className="text-body-secondary small">Última atualização <TimeAgo date={Date.parse(jobData.editado_em)} locale='pt'/></span></p> 
                    </Collapse> 
                </div>
            </Button>
        </div>
    )

}