// import logo from './logo.svg';
// import './App.css';
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Alert, Container, Row, Col, Form, Button, FloatingLabel } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import NavigationBar from "../components/NavigationBar";
import AuthService from "../services/AuthService";


function Login() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState(null);
    const [error, setError] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    function handleLoginClick(event){
      event.preventDefault();

      AuthService.login(email, password).then(response => {
        navigate(location?.state?.from || '/cargos');
      }).catch(err => {
        console.log(err);
        if (err.response && err.response.status === 401){
          setError('E-mail ou senha incorretos');
          return;
        }
        
        setError('Erro ao tentar fazer login. Tente novamente mais tarde.');
      })
    }

    useEffect(() => {
      if (AuthService.isAuthenticated()){
        navigate(location?.state?.from || '/cargos');
      }
    }, []);

    return (
      <>
        <NavigationBar logado={false}/>
        <Container fluid className="d-flex align-items-center justify-content-center" style={{ height: '75vh' }}>
          <Row className="w-100" style={{maxWidth: '300px'}}>
            <Col xs={12} md={12} lg={12}>
              <Form>
                <h3 className="mb-4 text-center">Conecte-se</h3>

                <FloatingLabel controlId="floatingInput" label="E-mail">
                  <Form.Control type="email" placeholder="Digite seu e-mail" onChange={(e) => {setEmail(e.target.value)}} style={{borderBottomRightRadius: 0, borderBottomLeftRadius: 0}}  />
                </FloatingLabel>

                <FloatingLabel controlId="floatingPassword" label="Senha" className="mb-3">
                  <Form.Control type="password" placeholder="Digite sua senha" onChange={(e) => {setPassword(e.target.value)}} style={{borderTopRightRadius: 0, borderTopLeftRadius: 0, borderTopWidth: 0}} />
                </FloatingLabel>

                <Button variant="primary" type="submit" className="w-100 mt-3" onClick={handleLoginClick}>
                  Entrar
                </Button>
              </Form>
            </Col>
            <Col xs={12} md={12} lg={12} className="mt-4">
              {error && <Alert variant="danger" size="small">{error}</Alert>}
            </Col>
          </Row>
        </Container>
      </>
    
  )}

export default Login;
